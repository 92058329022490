import React from "react";

import Box from "components/Box";
import BlockContent from "components/BlockContent";

const style = {
  container: {
    width: "100%",
    maxWidth: "lg",
    mx: "auto",
    mb: 6,
  },
  text: {
    // mx: [0, 0, -3],
    px: 3,
  },
};

const Text = ({ blocks, sx }) => {
  return (
    <Box sx={{ ...style.container, ...(sx || {}) }}>
      <BlockContent sx={style.text} blocks={blocks} />
    </Box>
  );
};

export default Text;
